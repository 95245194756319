.application {
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 10px;
    width: 120px;

    &:hover {
        cursor: pointer;
        opacity: .7;
        transition: .3s;
    }
}

.application-title {
    font-weight: bold;
}

.application-status {
    color: green;
}

.application-image {
    margin-top: 5px;
    width: 40px;
    height: 40px;
}
